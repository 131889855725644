<template>
  <b-container fluid class="mt-5">
    <b-form @submit.prevent="createArticle">
      <b-row>
        <b-col>
          <h2>Create Article</h2>
        </b-col>
        <b-col cols="*" class="mr-3">
          <b-button
            variant="primary"
            type="submit"
            :disabled="slug != last_valid_slug"
            >Create <b-spinner small v-if="creating"
          /></b-button>
        </b-col>
      </b-row>
      <hr class="mt-1 mb-5" />

      <b-row>
        <b-col cols="9">
          <b-form-input
            placeholder="Enter title"
            v-model="title"
            autocomplete="off"
            class="mb-3"
            required
          ></b-form-input>
          <b-row class="mb-3" align-v="center">
            <b-col>
              <b-input-group>
                <b-form-input
                  autocomplete="off"
                  :state="slug == last_valid_slug"
                  placeholder="enter slug i.e., some-article-slug"
                  v-model="slug"
                ></b-form-input>
                <template v-slot:append>
                  <b-button
                    variant="primary"
                    @click="checkSlug"
                    :disabled="slug.length == 0"
                    >Check</b-button
                  >
                </template>
              </b-input-group>
            </b-col>
            <b-col>
              <v-select
                label="name"
                :options="all_authors"
                :multiple="true"
                v-model="authors"
                placeholder="Add authors"
              >
              </v-select>
            </b-col>
            <b-col cols="*" class="mr-3">
              <b-form-checkbox
                v-model="show_authors"
                name="check-button"
                switch
                size="lg"
              >
                Show author(s)
              </b-form-checkbox>
            </b-col>
            <b-col cols="*" class="mr-3">
              <b-form-checkbox
                v-model="publish"
                name="check-button"
                switch
                size="lg"
              >
                Ready to Publish
              </b-form-checkbox>
            </b-col>
            <b-col cols="*" class="mr-3">
              <b-form-checkbox
                v-model="full_width"
                name="check-button"
                switch
                size="lg"
              >
                Approved by Compliance
              </b-form-checkbox>
            </b-col>
          </b-row>

          <editor
            api-key="q0awhe295b0tc22uvdwyerg0ixibx092yk0gqklk8c7dbsap"
            v-model="content"
            :init="init"
          />
        </b-col>

        <b-col cols="3">
          <b-form-select
            v-model="article_type"
            :options="options"
            class="mb-3"
            required
          ></b-form-select>

          <b-form-tags
            input-id="keywords"
            v-model="keywords"
            placeholder="Add keywords"
            class="mb-3"
          ></b-form-tags>

          <b-form-textarea
            v-model="description"
            placeholder="Enter description"
            rows="5"
            class="mb-3"
          >
          </b-form-textarea>
          <h5>Thumbnail Photo</h5>

          <b-form-input
            autocomplete="off"
            placeholder="Enter thumbnail URL or upload image"
            v-model="thumbnail_url"
            required
            class="mb-3"
          ></b-form-input>

          <b-img-lazy
            v-if="thumbnail_url.length != 0"
            :src="thumbnail_url"
            fluid
            class="mb-3"
          />
          <b-form-file
            v-model="thumbnail_file"
            :state="Boolean(thumbnail_file)"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            class="mb-3"
          ></b-form-file>
          <b-row>
            <b-col>
              <b-button
                class="mr-2"
                variant="primary"
                @click="uploadThumbnail"
                block
                :disabled="!Boolean(thumbnail_file) || uploading"
                >Upload <b-spinner small v-if="uploading"
              /></b-button>
            </b-col>
            <b-col>
              <b-button
                @click="thumbnail_file = null"
                :disabled="uploading"
                block
                >Clear</b-button
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";

export default {
  components: {
    editor: Editor,
  },
  data() {
    return {
      last_valid_slug: "",
      all_authors: [],
      article_type: null,
      options: [
        { value: null, text: "Please select an article type" },
        { value: "article", text: "Article" },
        { value: "video", text: "Video" },
        { value: "news", text: "News" },
      ],

      uploading: false,
      thumbnail_file: null,
      show_thumbnail: true,
      creating: false,
      title: "",
      slug: "",
      publish: true,
      show_authors: true,
      full_width: false,
      description: "",
      authors: [],
      keywords: [],
      thumbnail_url: "",
      content: "",
      init: {
        height: 500,
        image_advtab: true,
        plugins: [
          "advlist autolink lists link image imagetools charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
        ],
        toolbar:
          "insert | undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help",
        images_upload_handler: async function (
          blobInfo,
          success,
          failure,
          progress
        ) {
          const re = /(?:\.([^.]+))?$/;

          const ext = re.exec(blobInfo.filename())[1];
          try {
            let response = await this.$http.post(
              this.$store.state.apiURL + "/media",
              {
                name: "",
                description: "article thumbnail",
                media_type: "image",
                file_extension: ext,
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.JWT,
                },
              }
            );

            var options = {
              headers: {
                "Content-Type": "image/" + ext,
                "x-amz-acl": "public-read",
              },
              onUploadProgress: function (progressEvent) {
                let percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                progress(percentCompleted);
              },
            };

            await this.$http.put(
              response.data.presigned_url,
              blobInfo.blob(),
              options
            );

            let confirmResponse = await this.$http.post(
              this.$store.state.apiURL + "/media/" + response.data.id,
              {
                confirm_action: "url",
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.JWT,
                },
              }
            );

            success(confirmResponse.data.url);
          } catch (error) {
            failure("Failed to upload file.");
          }
        }.bind(this),
      },
    };
  },
  methods: {
    async checkSlug() {
      try {
        await this.$http.get(
          this.$store.state.apiURL + "/articles/slug/" + this.slug + "/valid",
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.JWT,
            },
          }
        );
        this.last_valid_slug = this.slug;
      } catch (error) {
        //
      }
    },
    async upload(blobInfo, success, failure, progress) {
      const re = /(?:\.([^.]+))?$/;

      const ext = re.exec(blobInfo.filename())[1];
      try {
        let response = await this.$http.post(
          this.$store.state.apiURL + "/media",
          {
            name: "",
            description: "article thumbnail",
            media_type: "image",
            file_extension: ext,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.JWT,
            },
          }
        );

        var options = {
          headers: {
            "Content-Type": "image/" + ext,
            "x-amz-acl": "public-read",
          },
          onUploadProgress: function (progressEvent) {
            let percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            progress(percentCompleted);
          },
        };

        await this.$http.put(
          response.data.presigned_url,
          blobInfo.blob(),
          options
        );

        let confirmResponse = await this.$http.post(
          this.$store.state.apiURL + "/media/" + response.data.id,
          {
            confirm_action: "url",
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.JWT,
            },
          }
        );

        success(confirmResponse.data.url);
      } catch (error) {
        failure("Failed to upload file.");
        this.handleError(error);
      }
    },
    async uploadThumbnail() {
      this.uploading = true;
      try {
        const re = /(?:\.([^.]+))?$/;

        const ext = re.exec(this.thumbnail_file.name)[1];
        let response = await this.$http.post(
          this.$store.state.apiURL + "/media",
          {
            name: "",
            description: "article thumbnail",
            media_type: "image",
            file_extension: ext,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.JWT,
            },
          }
        );

        var options = {
          headers: {
            "Content-Type": "image/" + ext,
            "x-amz-acl": "public-read",
          },
        };

        await this.$http.put(
          response.data.presigned_url,
          this.thumbnail_file,
          options
        );

        let confirmResponse = await this.$http.post(
          this.$store.state.apiURL + "/media/" + response.data.id,
          {
            confirm_action: "url",
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.JWT,
            },
          }
        );

        this.thumbnail_url = confirmResponse.data.url;
        this.thumbnail_file = null;
      } catch (error) {
        this.handleError(error);
      }
      this.uploading = false;
    },
    async createArticle() {
      this.creating = true;
      try {
        // create article
        let response = await this.$http.post(
          this.$store.state.apiURL + "/articles",
          {
            title: this.title,
            slug: this.slug,
            html: this.content,
            description: this.description,
            thumbnail_url: this.thumbnail_url,
            show_thumbnail: this.show_thumbnail,
            authors: this.authors.map((author) => author.id),
            show_authors: this.show_authors,
            published: this.publish,
            full_width: this.full_width,
            keywords: this.keywords,
            article_type: this.article_type,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.JWT,
            },
          }
        );
        this.$router.push({
          name: "editArticle",
          params: { id: response.data.id },
        });
      } catch (error) {
        this.handleError(error);
      }
      this.creating = false;
    },
  },
  async created() {
    // load in users
    let users = await this.$http.get(
      this.$store.state.apiURL + "/users/admins",
      {
        headers: {
          Authorization: "Bearer " + this.$store.state.JWT,
        },
        params: {
          admin: true,
        },
      }
    );

    let user = await this.$http.get(
      this.$store.state.apiURL + "/users/admins/" + this.$store.state.id,
      {
        headers: {
          Authorization: "Bearer " + this.$store.state.JWT,
        },
      }
    );
    let temp = users.data.data.concat([user.data]);
    this.all_authors = temp.map((user) => {
      return {
        id: user.id,
        name: user.first_name + " " + user.last_name,
      };
    });
  },
};
</script>

<style>
</style>